import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InfoModule from './infoModule';
import PictureModule from './pictureModule'
import { styled } from '@material-ui/core/styles';
import { Box, Typography, Link } from '@material-ui/core';

//Fade Lib
import 'aos/dist/aos.css';
import '../styles/boxAnimation.css';
import moment from 'moment';


export default class infoPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeScroll: false,
            currentProjects: [],
            loading: false,
            currentPageIndex: []
        };
        
        this.handleScroll = this.handleScroll.bind(this);
    
    }


    handleScroll() {
        this.setState({ activeScroll: true });
    }


    //@DESC: Update States on Component Mount
    componentDidMount() {


        const fetchProjects = async () => {
            fetch(process.env.REACT_APP_PROJECTS)
                .then(response => response.json())
                .then(response => this.setState({ currentProjects: response.items }))
                .then(() =>this.formatRecentArray());
            this.setState({ loading: true });
        }
        fetchProjects()
        
        
        //sets the time before the project details scroll in
    }

    //@DATA: Handles the 4 that are displayed for recent data
    formatRecentArray()
    {
        var recentProjects=[];
        var amountOnRecentScreen = this.state.currentProjects.length-4;
        var blogIndex = [];

        if(this.state.currentProjects.length > 4)
        {
            for(var i = this.state.currentProjects.length; i > amountOnRecentScreen;i--)
            {
                recentProjects.push(this.state.currentProjects[i-1]);
                console.log(i-1)
                blogIndex.push(i-1)
            }
            this.setState({ currentProjects: recentProjects});
            this.setState({currentPageIndex:blogIndex})
            
        }
        
        
    }



    render() {


        let flipPanel = false;
        
        //compenent styling for the containers 
        const StyledContainer = styled(Box)({
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            minHeight: '400px',
            marginRight: '3%'
        });

        //component styling for the background
        const FeatureContainer = styled(Box)({
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            alignContent: 'center',
            paddingLeft: '0.5%',
            paddingRight: '0.5%',
            paddingBottom: '0.5%',
            paddingTop: '0.5%',
           
            marginRight: '3%',
            


        });

        let width = window.innerWidth;
      
        //flip panel allows for each picture and there relevant info to display opposite to the one prior
        if (this.state.loading) {
            if (width > 768) {
               
                return (

                    <Container maxWidth='xl'>
                        <Grid container>
                            {
                                this.state.currentProjects.reverse().map((project,i) => {
                                
                                    if (flipPanel === true) {
                                        flipPanel = false;

                                        return (

                                            <Grid container data-aos="fade-left" key={project.title} style={{ marginTop: '3%' }}>
                                                <Grid item xs={6}>
                                                    <FeatureContainer className='gradient-border'>
                                                        <PictureModule thumbnailImage={project.images[0].url} />
                                                    </FeatureContainer>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <StyledContainer>
                                                        <InfoModule project={project} index={this.state.currentPageIndex[i]} length={this.state.currentProjects.length}/>
                                                    </StyledContainer>
                                                </Grid>
                                            </Grid>

                                        )
                                    }
                                    else 
                                        flipPanel = true;
                                       
                                    return (

                                        <Grid container data-aos="fade-right" key={project.title} style={{ marginTop: '3%' }}>
                                            <Grid item xs={6}>
                                                <StyledContainer>
                                                    <InfoModule project={project} index={this.state.currentPageIndex[i]} length={this.state.currentProjects.length}/>
                                                </StyledContainer>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <FeatureContainer className='gradient-border'>
                                                    <PictureModule thumbnailImage={project.images[0].url} />
                                                </FeatureContainer>

                                            </Grid>
                                        </Grid>

                                    )


                                })}
                        </Grid>
                    </Container>

                )
            }
            //mobile view
            else {
                return (
                    <Container >
                        <Grid container>
                        <Grid xs={12} item  style={{color:'white',textAlign:'center'}}>
                        <Typography variant='h6' >Current Projects</Typography>
                        </Grid>
                        <Grid xs={12} item style={{color:'white',textAlign:'center'}}>
                        <ul>
                            {this.state.currentProjects.reverse().map((project, i) => {
                                return (
                                    <Grid item  data-aos="fade-right"  key={project.title}>
                                        <Link  style={{color:'#E75480'}}href={`/projects/${project.title + this.state.currentPageIndex[i]}`}>{project.title} <p>Published: {moment(project.published).calendar()} </p></Link>
                                       
                                    </Grid>
                                )
                            })}
                            </ul>
                            </Grid>
                        </Grid>
                    </Container>
                )
            }
        }

        else {
            return (
                <Container>
                    <Typography style={{ textAlign: 'center', color:'white' }} variant='h1'>
                        Content Loading
                    </Typography>
                </Container>
            )
        }
    }
}
