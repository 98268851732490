import React, { Component } from 'react'
import { Typography, Grid, Container, Box } from '@material-ui/core'
import GitHubIcon from '@material-ui/icons/GitHub'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Link } from "react-router-dom";
import moment from 'moment'




export default class infoModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ""

        };
    }

  
    render() {     
        return (
            <Box style={{ backgroundColor: 'white', display: 'flex', flexGrow: '1', flexDirection: 'column', alignSelf: 'center', minHeight: '400px' }}>
                <Typography variant="h3" style={{ marginBottom: '3%', marginTop: '1%', textAlign: 'center', alignContent: 'center' }}>{this.props.project.title}</Typography>


                <Container style={{ minHeight: '40px', overflow: 'hidden', alignItems: 'center', display: 'flex', }}>
                    <Typography variant='subtitle2'>Project Published:  {moment(this.props.project.published).calendar()} </Typography>

                </Container>

                <Container style={{ minHeight: '170px', overflow: 'hidden', alignItems: 'center', display: 'flex', }}>
                    <Typography dangerouslySetInnerHTML={{__html: this.props.project.content.toString().substring(0,1000)+"..."}}></Typography>
                </Container>


                <Grid container alignItems='center' justify='center' wrap='nowrap'>
                    <Grid item xs={6} style={{ textAlign: "center" }}>
                        <IconButton href='#'>
                            <GitHubIcon fontSize='large' />
                        </IconButton>
                        <Typography variant='subtitle1'>GitHub</Typography>

                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "center" }}>
                        <Link to={`/projects/${this.props.project.title+ (this.props.index)}`} onClick={()=>window.scrollTo(0,0)}>
                            <IconButton>
                                <MoreHorizIcon fontSize='large' />
                            </IconButton>
                        </Link>
                        <Typography variant='subtitle1'>More Info</Typography>
                    </Grid>
                </Grid>

            </Box>
        )

    }
}
