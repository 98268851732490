import React, { Component } from 'react'
import '../styles/footer.css';
import { Typography } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import IconButton from '@material-ui/core/IconButton';
import { grey } from '@material-ui/core/colors';


export default class footer extends Component {
    render() {
        return (
            <div className='footerContainer'>
                <div className="footer">
                    <div className="footerContents ">
                        <Typography className='footerText'>Created By Nicholas Anzellotti </Typography>
                        <div className='socialLinks'>
                        <IconButton href='https://www.linkedin.com/in/nicholas-anzellotti-47b847192/'    style={{ marginRight: '5%' }}>
                            <LinkedInIcon color="primary" style={{ color: grey[50] }} />
                        </IconButton>
                        <IconButton href='https://www.google.com'  style={{ marginRight: '5%' }}>
                            <InstagramIcon color="primary" style={{ color: grey[50], maringTop:'-10%' }} />
                        </IconButton>
                        <IconButton  href='https://github.com/NickAnz' >
                            <GitHubIcon color="primary" style={{ color: grey[50] }} />
                        </IconButton>
                        </div>
                        <Typography  ><a className='email' href='mailto: nanzellotti@live.com'>Contact Me</a> </Typography>
                    </div>
                    <div style={{ clear: 'both'}}>

                    </div>
                </div>

            </div>
        )
    }
}
