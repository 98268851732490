import React, { Component } from 'react';
import '../styles/detailedView.css';
import { Typography, Grid, Container } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import 'aos/dist/aos.css';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

const createDOMPurify = require('dompurify');
const { JSDOM } = require('jsdom');

const window = new JSDOM('').window;
const DOMPurify = createDOMPurify(window);




export default class detailedModule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProject: "",
            loading: true
        };

    }


    componentDidMount() {
        //regexp to replace the url data to find the title of the project
        const request = async () => {
            var index = 0;
            const fetchProject = await fetch(process.env.REACT_APP_PROJECTS);
            var json = await fetchProject.json()
            index = json.items.length - 1;

            index = index - document.URL[document.URL.length - 1];
            try {
                var clean = DOMPurify.sanitize(json.items[index].content);
            } catch (error) {
                this.props.history.push('/');
            }
           
            this.setState({
                currentProject: json.items[index],
                content: clean,
                loading: false
            });
        }


        request();



    }





    render() {
        //used to ensure that the database has loaded and the page has been returned correctly
        if (!this.state.loading) {
            return (
                <div className='detailedView'>
                    <Container className='projectBox'>
                        <Grid container  >

                            <Typography variant='h4' style={{ marginBottom: '3%', marginTop: '2%', textAlign: 'center', display: 'block', width: '100%' }}>{this.state.currentProject.title}</Typography>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' ><strong>Published:</strong>  {moment(this.state.currentProject.published).calendar()} </Typography>
                                <Divider />
                            </Grid>
                            <Divider />
                            <Grid container >

                                <Typography style={{ display: 'block', width: '100%' }} dangerouslySetInnerHTML={{ __html: this.state.content }} ></Typography>


                                <Grid item xs={12} style={{ marginBottom: '2%' }}>
                                    <Divider />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>


                </div >

            )
        }
        else {
            return (
                <div className='detailedView'>
                    
                </div>



            )
        }
    }
}
