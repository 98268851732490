import React from 'react';
import InfoPanel from '../components/infoPanel'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/background.css'
import SplashScreen from '../components/styledContiner'
//Fade Lib
import AOS from 'aos'
import 'aos/dist/aos.css';


function App() {
  AOS.init();


  
  return (
    <div className='background'>
      <SplashScreen />
      <InfoPanel />
    </div>
  );
}


export default App;
