import React, { Component } from 'react'
import {Box } from '@material-ui/core'


export default class pictureModule extends Component {

    

    render() {

     
      
        return (
            <Box  fixed='true' style={{backgroundImage:`url(${this.props.thumbnailImage})`, backgroundColor:'transparent', backgroundSize:'contain', display:'flex',backgroundPosition:'center center', backgroundRepeat: 'no-repeat', width:'100%', height:'100%'}}>
            </Box>
        )
        
        
    }
}
