import React, { Component } from 'react'
import { Container, Typography, Box } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import '../styles/background.css'


export default class styledContiner extends Component {
  


    render()
    {
       

        return (
            <Container className='introCard' >
                <Fade in={true} timeout={3000}>
                <Box style={{backgroundColor:'transparent'}} >
                <Typography variant='h1' style={{color:'white'}}>Projects After Dark</Typography>
                
                <Typography variant='h6' style={{color:'white'}}>A Portfolio Created In ReactJS</Typography>
                </Box>
                </Fade>
            </Container>
        )
    }
}
