import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
    useLocation
} from "react-router-dom";
import Home from './pages/home'
import Detail from './pages/detailedModule'
import About from './pages/aboutme'
import Projects from './pages/projects'
import Header from './components/header'
import Footer from './components/footer'
import NotFound from './pages/pageNotFound'
import {CSSTransition, TransitionGroup} from "react-transition-group";
import './styles/animation.css'

function App() {
    let location = useLocation();

  return (

<div style={{background:'black'}}>
      <Header />
      <TransitionGroup>
          <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={300} >

      <Switch location={location}>
        <Route exact key="home" path="/" component={Home} />
        <Route key ="projectsType" path="/projects/:id"  component={Detail} />
        <Route key ="about" path="/about" component={About} />
        <Route key ="Projects"path='/projects' component={Projects} />

        <Route component={NotFound} />
      </Switch>
          </CSSTransition>
      </TransitionGroup>
      <Footer />


</div>

  );
}

export default App;
