import { Container, Fade, Typography } from '@material-ui/core'
import React, { Component } from 'react'
 

export default class pageNotFound extends Component {
    render() {
        return (
   
            <div className='notFoundModule'>
            <Container style={{height:'100vh' ,paddingTop:'20%'}}>
            <Fade in={true} timeout={2500}>
                <div>
                <Typography variant= 'h1' style={{color:'white',textAlign:'center'}}>Sadly That Page Isnt Found</Typography>
                <Typography variant= 'h6' style={{color:'white',textAlign:'center'}}>Mabye One Day Something Might Be Here</Typography>
                </div>
                </Fade>
            </Container>
            </div>
            
            )
    }
}
