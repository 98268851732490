import React from 'react'
import { Typography, Container, Grid } from '@material-ui/core'
import portrait from '../img/myself.jpeg'
import '../styles/about.css'


export const aboutMe = () => {
    return (
        <div className='aboutModuleBak'>
            <Container>
                <div className='aboutModule'>
                <Container>
                    <Grid container>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant='h3' style={{ marginTop: '2%' }}>About The Developer</Typography>
                            <Typography variant='subtitle1' style={{ textAlign: 'left', paddingRight: '2%' }}>
                                <ul>
                                    <li> <strong>Date Of Birth:</strong> 07/10/1998  </li>
                                    <li><strong>Studying:</strong> Hounours Of Software Engineering & Bachelor Of Computer Science </li>
                                    <li> <strong>Email:</strong>
                                        <a href='mailto:nanzellotti@live.com'> nanzellotti@live.com </a></li>
                                </ul>

                            </Typography>
                        </Grid>
                    </Grid>
                    </Container>
                    <img className='media' src={portrait} alt='portrait' />
                    <Container>
                        <Typography paragraph={true} ><em>Just because something doesn’t do what you planned it to do doesn’t mean it’s useless- Thomas A. Edison </em></Typography>
                        <Typography paragraph={true}>Engineering is a unique art form in which technology can be developed to automate, construct and design solutions that help everyday humans like you and me to live in a coherent society, free from the struggles of the past such as transportation or even a simple light. </Typography>
                        <Typography paragraph={true}>As a child I’ve always sought to create things that help the world through means of technology, as a child i would pull apart computers and analyse there insides (after shortly breaking them) and try to figure out how they work and how to repair and optimise machines to work efficiently for productivity and entertainment purposes, in my teenage years I obtained a job at a computer store and spent my days troubleshooting and setting up systems. Through high school I developed a love for coding and developing solutions that seemed very boring and mundane but helped immensely through everyday tasks such as an automatic file uploader or just a simple script that capitalises each word in a file. </Typography>
                        <Typography paragraph={true}>While these solutions were incredibly simple it allowed me to develop and explore the possibilities that I could achieve in life with languages such as <em> Python, C++, C#, JavaScript, ECMAScript6 and Unix” </em>. After high school l progress into my <strong> Honours in Software Engineering and Computer Science Majoring in Network Design </strong>at Swinburne University where I developed a fascination in microcontrollers and how they can be used to automate and create anything imaginable. This lead to the creation of my first drone built off the Ardupilot flight controller, weeks of testing and configuring and tweaking were undertaken but and the end of the day  these were all overcome to make a Multirotor and were further improved on when creating my second iteration in TriCopter form with gimbal control for a client</Typography>
                        <Typography paragraph={true}>I also developed a love for networks and web development creating this portfolio site using all React frontend and a NoSQL backend through <em>MongoDB and ExpressJS </em> Using these tools I was able to create this site that reads from a database and pragmatically creates each projects page to provide a more detailed look into each. While throughout my studies we were taught MySQL extensively I’m always looking on ways to improve my knowledge and better myself through technology hence my exploration into NoSQL. When these tools are so close within reach, I find myself learning more and more each day. </Typography>
                        <Typography paragraph={true}> The beautiful perk of working in software engineering is that were never done learning and creating. Something that could be created one day could help thousands of people later down the road to develop something even greater. The motivation I have to push myself and create something amazing will always remain in me from seeing the smiles of peoples who’s systems have been repaired to hosting my first web server and securing it with SSL, I look forward to seeing where technology goes in our life and I’m thrilled to be a part of the journey. </Typography>
                        <em> -Nicholas A </em>

                    </Container>

                </div>

            </Container>


        </div>
    )
}

export default aboutMe;