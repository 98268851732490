import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import '../styles/navbar.css';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import { styled } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        fontSize: '1.5em',
        overflowWrap: 'break-word',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            fontSize: '3em',

        },
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: '30%',


        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',

        },
    },
}));

export default function PrimarySearchAppBar() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };


    const StyledLinks = styled(Link)({

        color: 'white',

        marginRight: '20%',
        transition: 'color 0.5s',
        '&:hover': {
            color: "#33ccff",
            textDecoration: 'none',

        },
        fontSize: '20px',
        alignContent: 'left',
        fontFamily: 'Kanit ,sans-serif'

    });



    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>About</MenuItem>
            <MenuItem onClick={handleMenuClose}>Projects</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <StyledLinks to='/about' style={{ color: 'black' }}>About</StyledLinks>
            </MenuItem>
            <MenuItem>
                <StyledLinks to='/projects' style={{ color: 'black' }}>Projects</StyledLinks>
            </MenuItem>
            <MenuItem>

               <p className={'left'}><a  className={"resumeButton"}  href='https://drive.google.com/open?id=1MOMnHyIPho1Th5LTwOLB4eu0hXSWTdga' style={{ color: 'black' }}>Resume</a> </p>
            </MenuItem>
        </Menu>
    );

    return (
        <div className={classes.grow} style={{ zIndex: '5' }}>
            <AppBar position="relative" style={{background:'linear-gradient(90deg, rgba(14,11,12,1) 11%, rgba(35,8,21,1) 35%, rgba(35,8,21,1) 66%, rgba(14,11,12,1) 90%)'}}>
                <Toolbar>
                   
                    <Typography  className={classes.title} style={{ fontFamily: 'Kanit ,sans-serif' }} variant="h3" >
                        <Link style={{color:'white', textDecoration:'none'}}to='/'>Nicholas Anzellotti</Link>
          </Typography>

                    <div style={{ flexGrow:1}} />
                    <div className={classes.sectionDesktop}>

                       
                        <StyledLinks  className='left'to='/about'>About</StyledLinks>
                        <StyledLinks className='left' to='/projects'>Projects</StyledLinks>
                       <a  className={"left"}  href='https://drive.google.com/open?id=1MOMnHyIPho1Th5LTwOLB4eu0hXSWTdga' >Resume</a>

                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}
