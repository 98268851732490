import React, { Component } from 'react'
import { Container, Typography } from '@material-ui/core';
import '../styles/about.css'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import {Link} from 'react-router-dom';


export default class projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentProjects: [],
            isLoaded: false,
            currentNumberOfProjects: 0
        };

        this.setprojectsHTML = this.setprojectsHTML.bind(this);
    }

    //strip relivant HTML tags
    setprojectsHTML(html) {
        var doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";

    }

    componentDidMount() {
       
        const fetchProjects = async () => {
            fetch(process.env.REACT_APP_PROJECTS)
                .then(response => response.json())
                .then(response => this.setState({ currentProjects: response.items, isLoaded:true, currentNumberOfProjects: response.items.length-1  })
                )}

        fetchProjects();
       

    }


    render() {
        
        if (!this.state.isLoaded) {
            return (
                <div className='aboutModuleBak'>
                    <Typography variant='h3' style={{ textAlign: 'center', paddingTop: '1%', color:'white' }}>Fetching Current Projects</Typography>
                </div>
            )


        }
        else {
            return (
                <div className='aboutModuleBak'>
                    <Container>
                        <div className='projectModule'>
                            <div className='projectPageHeader'>
                            <Typography variant='h3' style={{ textAlign: 'center', paddingTop: '1%' }}>Current Projects</Typography>
                            <Typography style={{ textAlign: 'center' }}>Presented here is a collection of my projects that have been compelted, clicking on the title will show more details about the solution/project</Typography>
                            </div>
                            <List>
                                {this.state.currentProjects.map((project, i) => {
                                    return (
                                        <React.Fragment key={project.title}>
                                            <ListItem >

                                                <ListItemText
                                                    primary={<Link to={`/projects/${project.title + (this.state.currentNumberOfProjects-i)}`}>{project.title}</Link>}
                                                    secondary={

                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="textPrimary">
                                                            <strong> Published: </strong>{moment(project.published).calendar()}
                                                            <p>{this.setprojectsHTML(project.content.toString().substring(0,1000)+"...")}</p>
                                                        </Typography>
                                                    }
                                                />

                                            </ListItem>
                                            <Divider variant="inset" />
                                        </React.Fragment>
                                    )
                                })}
                            </List>

                        </div>
                    </Container>


                </div>

            )

        }
    }

}
